.input-mask {
  border: 1px solid rgb(223, 223, 223);
  border-radius: 4px;
  box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  font-size: 0.937rem;
  font-weight: bold;
  color: rgb(20, 20, 20);
  padding: 16.5px 14px;
  width: 100%;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiTimePickerToolbar-root > .MuiGrid-root > .MuiButtonBase-root,
.MuiPickersToolbar-root > .MuiGrid-root > .MuiButtonBase-root {
  display: none;
}

.MuiCalendarPicker-root {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: #fff 0 0 0 1px, rgba(0, 0, 0, 0.2) 0 2px 4px;
}

.embla {
  overflow: hidden;
  height: 20rem;
}
.embla__container {
  display: flex;
  height: 100%;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem rgb(234, 234, 234);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem rgb(27, 52, 206);
}


