.main-header {
  font-size: 2rem;
  text-align: center;
}
.font-big-center {
  font-size: 1.2rem;
  text-align: center;
}
.result-msg {
  font-size: 2rem;
  text-align: center;
}
