.container {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1.5rem;
  margin-bottom: auto;
  margin-top: auto;
}

.table {
  margin-top: 1em;
}

.table thead tr th:first-child {
  font-weight: bold;
}

.tableActions button {
  min-width: 0;
}

.table button {
  padding: 0;
}

.toggleButton {
  text-transform: none;
  border: 1px solid #a6b5ca !important;
  border-radius: 8px !important;
  font-weight: 500;
  font-size: 1rem;
}
.toggleButtonIcon {
  margin-right: 0.2em;
  position: relative;
  top: -0.02em;
}
.record {
  color: #000000;
  background-color: #ffffff !important;
  border: 1px solid #a6b5ca;
  border-radius: 8px;
}
.record svg {
  color: #dc3a3a;
}
.record:disabled {
  background: #f1f1f9;
}
.record:disabled svg {
  color: #858585;
}

.settingsPaper {
  position: relative;
  padding: 0px 1em 1em 1em;
  min-width: 290px;
  width: 100%;
  overflow: scroll;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid #a6b5ca;
}
.settingsPaper fieldset {
  width: 100%;
}
.settingsPaper h2 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
}
.settingsPaper > h2:nth-of-type(2) {
  margin-top: 2rem;
}
.settingsPaper h2 button {
  min-width: 30px;
  padding: 0;
}
.settingsPaper h2 button > svg {
  margin-right: 0;
}

.settingsPaper .noStream {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  margin-top: 0.3rem;
  font-weight: 500;
  font-size: 12px;
}

.droppable {
  max-height: 165px;
  /* overflow: scroll; */
  padding-left: 5px;
}

.settingsButton {
  position: absolute;
  right: 5px;
  top: 4px;
  cursor: pointer;
  transition: color 100ms ease-in-out;
}
.settingsButton:hover {
  color: #f9400d;
}

.stream {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #a6b5ca;
  border-radius: 8px;
  margin-bottom: 6px;
  transition: all 200ms ease-in-out;
  background: #ffffff;
}
.stream > p {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}
.stream > div[data-svg="true"] {
  display: flex;
  align-items: center;
}
.stream > svg,
.stream > div[data-svg="true"] {
  color: #a6b5ca;
  transition: color 150ms ease-in-out;
  font-size: 20px;
}
.stream > svg {
  cursor: pointer;
}
.stream > svg:hover {
  color: #000000;
}
.stream > svg:first-of-type {
  margin-left: auto;
  margin-right: 0.5rem;
}
.stream.dragged {
  scale: 1.1;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.header {
  position: absolute;
  width: 102%;
  border-radius: 8px 8px 0 0;
  background-color: #444f6c;
  padding: 0.5rem 0.8rem;
  left: -1px;
}
.header > img {
  display: block;
}

.previewPaper {
  min-width: 500px;
  width: 100%;
}

.stepperContainer {
  grid-area: 2 / 2 / 3 / 3;
}
.stepper {
  gap: 20px;
  align-items: flex-start;
}
.step {
  width: 33%;
}
.stepContent {
  border-left: none;
}

.canvasContainer {
  background-color: #cecece;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.canvasContainer > p {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.videoOff {
  display: block;
  margin: 0 auto;
}

.formControl {
  position: relative;
  border: 1px solid #a6b5ca !important;
  border-radius: 8px;
}
.formControl > img {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.audioSelect fieldset {
  border: none !important;
}
.audioSelect div[role="button"] {
  padding: 10px 13px;
  padding-left: 30px;
  border: 1px solid #a6b5ca;
}

.toolButtonGroup {
  gap: 0.5rem;
}

.recordContainer {
  padding-top: 25px;
  margin-top: auto;
}

.record {
  display: block;
  padding: 1rem 1.5rem;
  border: 1px solid #a6b5ca;
  border-radius: 8px;
  background: #f1f1f9;
  max-width: fit-content;
  margin: 0 auto;
  text-transform: none;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.record:not([disabled]) {
  cursor: pointer;
}
.record > div {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.textsContainer > p {
  margin: inherit;
  margin-bottom: 5px;
  text-align: center;
}

@media (min-width: 540px) {
  .canvasContainer {
    min-width: 500px;
  }

  .settingsPaper {
    max-width: 360px;
  }
}

@media (min-width: 941px) {
  .settingsPaper,
  .previewPaper,
  .canvasContainer {
    max-height: 520px;
  }
}
@media (max-width: 940px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .columnsContainer {
    flex-direction: column;
    max-width: 100vw;
    padding-bottom: 3rem !important;
  }
  .settingsPaper {
    max-width: initial;
    grid-area: 1/1/2/2;
  }
  .previewPaper {
    min-width: initial;
    grid-area: 2/1/3/2;
  }
  .stepperContainer {
    position: initial;
    grid-area: 3/1/4/2;
  }
  .stepper {
    flex-direction: column !important;
    margin-top: 1rem;
  }
  .step {
    width: 100%;
  }
}
